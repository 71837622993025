import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import BensStory from '../components/bens-story';
import SectionHeader from '../components/section-header';

class StoryPage extends React.Component {

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
      const images = this.props.data.allContentfulAsset
      console.log(images)
      const headerData = {
        title: "Ben's Story"
      }
      const img2 = images.nodes.find(node => node.contentful_id=="2kX6pR7AiD78Kkoa7USMIc");
      const img1 = images.nodes.find(node => node.contentful_id=="4iDE0NVqxOZobukq7ohqm4");
      const img5 = images.nodes.find(node => node.contentful_id=="lN0B05gWl9JmUWuDiAO9E");
      const img6 = images.nodes.find(node => node.contentful_id=="420RxPRanaw4C0cpk4O3h1");
      const img3 = images.nodes.find(node => node.contentful_id=="4eVtuPLitrUXwTSLzpCnUB");
      const img4 = images.nodes.find(node => node.contentful_id=="9KzUVPT5p2DWW7DINyNng");
      const img7 = images.nodes.find(node => node.contentful_id=="3n0ykz2jyQgdTSx8yf8V9T");

    return (
      <Layout location={this.props.location}>
          <Helmet title={siteTitle} />
          <SectionHeader data={headerData} />
          <BensStory images={[img1,img2,img3,img4,img5,img6, img7]}/>
      </Layout>
    )
  }
}

export default StoryPage

export const pageQuery = graphql`
query StoryQuery {
  allContentfulAsset(filter: {contentful_id: {in: ["2kX6pR7AiD78Kkoa7USMIc", "4iDE0NVqxOZobukq7ohqm4", "lN0B05gWl9JmUWuDiAO9E", "420RxPRanaw4C0cpk4O3h1", "4eVtuPLitrUXwTSLzpCnUB", "9KzUVPT5p2DWW7DINyNng", "3n0ykz2jyQgdTSx8yf8V9T"]}}) {
    nodes {
      contentful_id
      file {
        url
      }
    }
  }
}
`
