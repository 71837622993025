import React from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import styles from './bens-story.module.css';

export default (props) => (
  <div className='wrapper'>
    <div className='section'>
      <p>Ben grew up in Nebraska, a childhood filled with swimming and inner-tubing at the lake, playing basketball, baseball, football and soccer, competing in everything from youth baseball world championships to high school varsity sports. Ben was an Honor Scholar at Southern Methodist University (SMU) Cox Business School where he graduated with a BBA in Finance and a Master’s of Accounting before working as an accountant at the Carlyle Group, one of the world’s largest private equity firms. </p>
    </div>
    <div className={styles.imageSection}>
      <img src={props.images[0].file.url} />
      <img src={props.images[6].file.url} />
      <img src={props.images[1].file.url} />

    </div>
  <div className='section'>
    <p>But Ben didn’t come away from SMU with just his degrees — he came away addicted to opiates. Intent on getting well, but desperately trying to avoid the shame and failure of losing his coveted scholarship and professional positions, Ben repeatedly tried detoxing both on his own and in outpatient therapy in graduate school and while working. Over and over the situation would become untenable. It wasn’t until he attended 28 days of in-patient detox and rehab followed immediately by 3 months in a sober living community with Intensive Outpatient Therapy that Ben succeeded in taking back control from his addiction and achieved sobriety. Experiencing the full continuum of care saved Ben’s life.</p>
  </div>

  <div className='section'><p>
  Once sober, Ben thrived. He became the CFO of Transcend Recovery, one of California’s largest sober living providers.  He then founded Pelorus Health Solutions, a technology startup focused on helping addicts in early recovery maintain their sobriety.  When Pelorus failed to gain traction and was dissolved, Ben entered the job market and joined GoPro as a financial analyst. Outside his ambitious professional pursuits, Ben took full advantage of the best California had to offer; he loved surfing, hiking, grilling, and live music.</p></div>
  <div className={styles.imageSection}>
    <img src={props.images[2].file.url} />
    <img src={props.images[3].file.url} />

  </div>
  <div className='section'><p>

After almost 5 years of living his best life in sobriety, Ben relapsed. Ben experienced undiagnosable deep stomach/low back pain, and his anxiety re-emerged with intensity.  Though he hid it well through his gregarious nature, his anxiety was real and caused him pain, both emotional and physical.
He worked hard to conquer his anxiety through therapy, meditation, yoga, music and being active in nature. Eventually, he returned to physician-prescribed drugs to help soothe the pain.  He also made the decision to introduce alcohol back into his life, reasoning that opiates had been his problem drug, not alcohol.  His argument was that with all the progress he had made, he could control his drinking to ‘just a glass of wine on a date’ or ‘just a beer or two at a ballgame’. </p></div>
<div className='section'><p>
But either before, during or after these decisions, Ben’s addiction was at play again, and eventually his relapse included other drugs.  His regular therapy appointments waned, his wellness program diminished, and he withdrew more and more from his solid sober social circle. The prescriptions and alcohol and stresses made for a slippery slope, and in just a few months his addiction was in control.</p></div>
<div className='section'><p>
On October 20, 2018, Ben’s life was tragically cut short by an accidental overdose due to street drugs laced with a lethal dose of fentanyl. Ben died at home alone in his apartment on a Saturday evening after watching his beloved Huskers and Dodgers play, texting with family and friends all through the games.</p></div>
<div className={styles.imageSection}>
  <img src={props.images[4].file.url} />
  <img src={props.images[5].file.url} />

</div>
<div className='section'><p>
The only way we can make sense of his decision to use again is that he was in pain, and he wanted to soothe it quickly. Ben was pretty good at most everything he did – varsity athlete, music aficionado, C-Suite executive, and yes, hiding his addiction.  We truly believe Ben was trying to get back to wellness and sobriety, but wanting to avoid the shame, stigma, and disappointment of his relapse, he was trying to go it alone instead of asking for help. Fentanyl took away any chance of re-entering recovery.</p></div>

<div className='section'><p>
The Ben Meyer Recovery Foundation has been born out of our desire to keep Ben’s energy alive and present in our lives, to continue the work that Ben cared so much about -- helping others find freedom in sobriety, and to share our stories to combat the stigma associated with addiction.
Specifically, our scholarship initiative will provide financial assistance for aftercare, an often overlooked part of addiction treatment that ultimately resulted in Ben's sobriety after years of trying. Based on our experiences and professional expertise, we believe this to be a crucial and worthwhile focus of the Ben Meyer Recovery Foundation.</p></div>

  </div>
)
